<div class="cp-toaster"
     [ngClass]="{
        'toggle': toaster.show,
        'success': toaster.alertType === AlertTypes.Success,
        'error': toaster.alertType === AlertTypes.Error
    }">
  @switch (toaster.alertType) {
    @case (AlertTypes.Success) {
      <ium-icon-exclamation-triangle class="cp-mr-8"></ium-icon-exclamation-triangle>
    }
    @case (AlertTypes.Error) {
      <ium-icon-exclamation-triangle class="cp-mr-8"></ium-icon-exclamation-triangle>
    }
  }

  <div class="flex-1">
    {{ toaster.description }}
  </div>

  <ium-icon-cross class="cp-ml-8 cursor-pointer" (click)="onClose()"></ium-icon-cross>

</div>

import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {ToastService} from './toast.service';
import {tap} from 'rxjs';
import {AlertTypes, Toaster} from "./toast.model";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {NgClass} from "@angular/common";
import {IumIconExclamationTriangle} from "../../ium-svg-icons/ium-icon-exclamation-triangle";
import {IumIconCross} from "../../ium-svg-icons/ium-icon-cross";

@Component({
  selector: 'ium-toast',
  standalone: true,
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  imports: [
    NgClass,
    IumIconExclamationTriangle,
    IumIconCross
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent {

  protected readonly AlertTypes = AlertTypes;

  toaster: Toaster = new Toaster();

  constructor(
    private readonly _toast: ToastService,
    private readonly _cdr: ChangeDetectorRef
  ) {
    this._toast.toaster$
      .pipe(
        tap(result => {
          if (result?.alertType == null) {
            return;
          }
          this.toaster = result;
          this._cdr.detectChanges();
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  onClose() {
    this._toast.hide();
  }

}

import {IumIconBaseComponent} from "./ium-icon-base";
import {ChangeDetectionStrategy, Component} from "@angular/core";
import {NgStyle} from "@angular/common";

@Component({
  selector: 'ium-icon-logout',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgStyle
  ],
  template: `
    <svg class="ium-svg-icon ium-svg-icon-logout"
         [ngStyle]="cssStyles"
         [attr.width]="width"
         [attr.height]="height"
         [attr.viewBox]="viewBox"
         [attr.fill]="background"
         xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 1.5C2.44771 1.5 2 1.94772 2 2.5V13.5C2 14.0523 2.44772 14.5 3 14.5H10.5C10.7761 14.5 11 14.2761 11 14C11 13.7239 10.7761 13.5 10.5 13.5H3V2.5L10.5 2.5C10.7761 2.5 11 2.27614 11 2C11 1.72386 10.7761 1.5 10.5 1.5H3ZM12.6036 5.39645C12.4083 5.20118 12.0917 5.20118 11.8964 5.39645C11.7012 5.59171 11.7012 5.90829 11.8964 6.10355L13.2929 7.5H6.5C6.22386 7.5 6 7.72386 6 8C6 8.27614 6.22386 8.5 6.5 8.5H13.2929L11.8964 9.89645C11.7012 10.0917 11.7012 10.4083 11.8964 10.6036C12.0917 10.7988 12.4083 10.7988 12.6036 10.6036L14.8536 8.35355C15.0488 8.15829 15.0488 7.84171 14.8536 7.64645L12.6036 5.39645Z"
            [attr.fill]="color"/>
    </svg>
  `
})
export class IumIconLogoutComponent extends IumIconBaseComponent {
}

<div>
  <header class="ium-header">
    <div>
      <img ngSrc="assets/images/logo.svg" alt="Launch Identity" priority height="32" width="209">
    </div>

    <div class="user-menu-container"
         (click)="onToggleUserMenu()"
         (clickOutside)="onHideUserMenu()">
      @if (authenticated$ | async) {
        <div class="user-avatar">
          <span class="user-name flex-center">{{ nameInitial | uppercase }}</span>
        </div>

        <div class="user-menu" [hidden]="!isUserMenuDisplayed">
          <a href="{{logoutUrl$ | async}}" (click)="onHideUserMenu()">
            <ium-icon-logout class="icon-logout"></ium-icon-logout>
            Logout
          </a>
        </div>
      }
    </div>
  </header>
</div>




import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AlertTypes, Toaster, ToastOptions} from "./toast.model";

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  readonly toaster$ = new BehaviorSubject<Toaster>(new Toaster());

  private readonly DEFAULT_DELAY = 5000;

  success(message: string) {
    this.show(message, {
      alertType: AlertTypes.Success
    });
  }

  error(message: string) {
    this.show(message, {
      alertType: AlertTypes.Error
    });
  }

  show(message: string, options?: ToastOptions) {
    this.toaster$.next({
      alertType: options?.alertType,
      description: message,
      show: true
    });

    this.resetToDefault(options?.delay);
  }

  hide() {
    const toast = this.toaster$.value;
    toast.show = false;
    this.toaster$.next(toast);
  }

  private resetToDefault(delay?: number) {
    setTimeout(() => {
      this.hide();
    }, delay ?? this.DEFAULT_DELAY);
  }

}

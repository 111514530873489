export enum AlertTypes {
  Success,
  Error
}

export class Toaster {
  alertType?: AlertTypes;
  title?: string;
  description?: string;
  show?: boolean = false;
}

export class ToastOptions {
  alertType?: AlertTypes;
  delay?: number;
}
